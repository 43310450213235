<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="participants"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Оролцогчид ({{ participants.length }})</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Бүртгэл+
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Оролцогч нэмэx</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Нэр"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.lastName"
                        label="Овог"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.register"
                        label="Регистр"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.phone"
                        label="Утасны дугаар"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Цуцлаx
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Xадгалаx
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
          >
            <v-card>
              <v-card-title
                class="headline"
              >
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeDelete"
                >
                  Цуцлаx
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteItemConfirm"
                >
                  OK
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template
        v-slot:
        item.actions="{ item }"
      >
        <v-icon
          small
          class="mr-2"
          
          @click.stop="dialog = true"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn @click="_initialize">
          Шинэчлэx
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>
<style>
.v-ripple__container {
  display: none !important;
}
</style>
<script>
  const fb = require('../../firebaseConfig.js')
  export default {
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        // return value.charAt(0).toUpperCase() + value.slice(1)
        return value.toUpperCase()
      },
    },
    props: {
      asuulgaId: {
        type: String,
        required: false,
      },
    },
    data: () => ({
      color: '#ccc',
      participants: [],
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Нэр',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Овог',
          align: 'start',
          sortable: false,
          value: 'lastName',
        },
        { text: 'Регистр', value: 'register' },
        { text: 'Утасны дугаар', value: 'phone' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        lastName: '',
        register: '',
        phone: '',
      },
      defaultItem: {
        name: '',
        lastName: '',
        register: '',
        phone: '',
      },
    }),
    watch: {
      questionType: function (val) {
        console.log(val)
        if (val === 0) this.question.multichoice = false
        else this.question.multichoice = true

        this._updateQuestion()
      },
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
    },
    created() {
      this._initialize()
    },
    methods: {
      _initialize() {
        fb.db
          .collection('asuulga/' + this.asuulgaId + '/users')
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            this.participants = []
            querySnapshot.forEach((userDoc) => {
              const user = userDoc.data()
              user.id = userDoc.id
              user.ref = userDoc.ref
              console.log(user)
              this.participants.push(user)
            })
          })
      },
      _editItem(item) {
        console.log(item)
        this.editedIndex = this.participants.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem(item) {
        this.editedIndex = this.participants.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      // fb.db.collection("asuulga/" + this.asuulgaId + "/users").doc(item.id);
      },
      deleteItemConfirm() {
        // this.participants.splice(this.editedIndex, 1);
        var deleteItem = this.participants[this.editedIndex]
        if (deleteItem) {
          fb.db.collection('asuulga/' + this.asuulgaId + '/users').doc(deleteItem.id).delete()
        }
        this.closeDelete()
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save() {
        var docRef
        this.editedItem.createdAt = new Date()
        if (this.editedIndex === -1) {
          // Object.assign(this.participants[this.editedIndex], this.editedItem);
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/users/').doc()
        } else {
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/users/').doc(this.editedItem.id)
        }
        docRef.set(this.editedItem)
        console.log(this.editedItem)
        this.close()
      },
    },
  }
</script>
